import { z } from 'zod'
import { Response } from '../../../../../../_metronic/helpers'

export const OrganisationSchema = z.object({
    id: z.string(),
    name: z.string(),
})

export const AddressSchema = z.object({
    id: z.string().optional(),
    createdDate: z.date().optional(),
    updatedDate: z.date().optional(),
    deleted: z.boolean().optional(),

    label: z.string().optional(),
    displayName: z.string().optional(),
    link: z.string().optional(),
    poBox: z.string().optional(),
    extendedAddress: z.string().optional(),
    street: z.string().optional(),
    city: z.string().optional(),
    stateProvince: z.string().optional(),
    postalCode: z.string().optional(),
    country: z.string().optional(),
})

export const EmailSchema = z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    value: z.string().optional(),
})

export const PhoneSchema = z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    value: z.string().optional(),
})

export const ChatSchema = z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    value: z.string().optional(),
})

export const RelateSchema = z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    value: z.string().optional(),
})

export const WebSchema = z.object({
    id: z.string().optional(),
    label: z.string().optional(),
    value: z.string().optional(),
})

export const UploadedFileSchema = z.object({
    id: z.string().optional(),
    createdDate: z.date().optional(),
    updatedDate: z.date().optional(),
    deleted: z.boolean().optional(),

    name: z.string().optional(),
    url: z.string().optional(),
    type: z.string().optional(),
})

export const SocialUrlSchema = z.object({
    id: z.string().optional(),
    createdDate: z.date().optional(),
    updatedDate: z.date().optional(),
    deleted: z.boolean().optional(),

    label: z.string().optional(),
    name: z.string().optional(),
    url: z.string().optional(),
    value: z.string().optional(),
})

export const ORGSchema = z.object({
    id: z.string(),
    name: z.string(),
})

export const ContactSchema = z.object({
    id: z.string().optional(),
    createdBy: z.string().optional(),
    createdDate: z.date().optional(),
    updatedBy: z.string().optional(),
    updatedDate: z.date().optional(),
    deleted: z.boolean().optional(),
    isShowCompanyName: z.boolean().optional(),
    namePrefix: z.string().optional(),
    firstName: z.string().optional(),
    phoneticFirstName: z.string().optional(),
    pronunciationFirstName: z.string().optional(),
    middleName: z.string().optional(),
    phoneticMiddleName: z.string().optional(),
    lastName: z.string().optional(),
    phoneticLastName: z.string().optional(),
    pronunciationLastName: z.string().optional(),
    maidenName: z.string().optional(),
    nameSuffix: z.string().optional(),
    nickname: z.string().optional(),
    formattedName: z.string().optional(),
    organisation: z.string().optional(),
    phoneticOrganisationName: z.string().optional(),
    department: z.string().optional(),
    csnNumber: z.string().optional(),
    title: z.string().optional(),
    role: z.string().optional(),
    phones: z.array(PhoneSchema).optional(),
    emails: z.array(EmailSchema).optional(),
    socialUrls: z.array(SocialUrlSchema).optional(),
    address: z.array(AddressSchema).optional(),
    chats: z.array(ChatSchema).optional(),
    birthday: z.date().optional(),
    anniversary: z.date().optional(),
    relates: z.array(RelateSchema).optional(),
    uid: z.string().optional(),
    gender: z.string().optional(),
    note: z.string().optional(),
    source: z.string().optional(),
    status: z.string().optional(),
    version: z.string().optional(),
    url: z.string().optional(),
    photo: z.string().or(z.number()).nullable().optional(),
    background: z.string().or(z.number()).nullable().optional(),
    opacity: z.number().nullable().optional(),
    logo: z.string().or(z.number()).nullable().optional(),
    vcfText: z.string().optional(),
})
export type ID = undefined | null | number | string

export type Contact = z.infer<typeof ContactSchema>

export type Organisation = z.infer<typeof OrganisationSchema>

export type Address = z.infer<typeof AddressSchema>

export type Email = z.infer<typeof EmailSchema>

export type Phone = z.infer<typeof PhoneSchema>

export type Chat = z.infer<typeof ChatSchema>

export type Relate = z.infer<typeof RelateSchema>

export type Web = z.infer<typeof WebSchema>

export type UploadedFile = z.infer<typeof UploadedFileSchema>

export type SocialUrl = z.infer<typeof SocialUrlSchema>

export type ORG = z.infer<typeof ORGSchema>

export type ContactsQueryResponse = Response<Array<Contact>>

export const initialContact: Contact = {}
